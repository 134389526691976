import Script from "next/script";

export default function GoogleAnalyticsScript(props)
{
    if (process.env.NODE_ENV != "production") {
        return null;
    }

    return (
        <>
            <Script
                src="https://www.googletagmanager.com/gtag/js?id=UA-173454520-1"
                strategy="afterInteractive"
            />
            <Script
                id="google-analytics"
                strategy="afterInteractive"
            >
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'UA-173454520-1');
                `}
            </Script>
        </>
    );
}
