import '../styles/globals.css';
import GoogleAnalyticsScript from "../components/google/googleAnalyticsScript";
import Head from 'next/head';
import React, { Component } from "react";

const schema = {
    "name": "Solvar Limited",
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://www.solvar.com.au",
    "logo": "https://www.solvar.com.au/img/logos/solvar_01.svg",
    "foundingDate": "2005-11-25",
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Level 1, 40 Graduate Road",
        "addressLocality": "Bundoora",
        "addressRegion": "VIC",
        "postalCode": "3083",
        "addressCountry": "AU"
    },
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+61-3-9093-8255",
        "contactType": "Investor Relations"
    },
    "brand": [
        {
            "@type": "Brand",
            "name": "Money3",
            "url": "https://www.money3.com.au"
        },
        {
            "@type": "Brand",
            "name": "Go Car Finance",
            "url": "https://www.gocar.co.nz"
        },
        {
            "@type": "Brand",
            "name": "Automotive Financial Services",
            "url": "https://www.afs.com.au"
        }
    ]
};


class MyApp extends Component
{
    constructor(props)
    {
        super(props)
    }

    render()
    {
        const { Component, pageProps } = this.props;

        return (
            <>
                <Head>
                    <title>solvar.com.au</title>
                    <link rel="shortcut icon" href="/favicon.ico" />
                    <link href="/favicon_128.png" rel="apple-touch-icon" />
                    <link href="/favicon_152.png" rel="apple-touch-icon" sizes="152x152" />
                    <link href="/favicon_167.png" rel="apple-touch-icon" sizes="167x167" />
                    <link href="/favicon_180.png" rel="apple-touch-icon" sizes="180x180" />
                    <link href="/favicon_192.png" rel="icon" sizes="192x192" />
                    <link href="/favicon_128.png" rel="icon" sizes="128x128" />
                    <meta name="description" content="Solvar Limited investors information website. View company announcements, ASX SVR share price, shareholder information and annual reports."></meta>
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
                    />
                </Head>
                <GoogleAnalyticsScript />
                <Component {...pageProps} />
            </>
        );
    }
}

export default MyApp;